import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { SharedStateContext } from "../main/SharedStateContext";
import { clientGet } from "../../actions/authAction";
import { checkTomorrowHoliday } from "../../actions/holidayAction";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import AlertLogo from "../../images/alert.png";

function SideBar() {
  const location = useLocation();
  const path = location.pathname;
  const { mobileSliderStatus, setMobileSliderStatus } =
    useContext(SharedStateContext);
  const [client, setClient] = useState({});
  const [holiday, setHoliday] = useState(false);
  const [newPairRange, setNewPairRange] = useState(false);

  const { id } = useParams();
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const client_state = useSelector((state) => state?.auth);
  const holiday_state = useSelector((state) => state?.holiday);

  const handleSlider = () => {
    if (mobileSliderStatus) {
      setMobileSliderStatus(false);
    } else {
      setMobileSliderStatus(true);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getClient = async () => {
      await clientGet(dispatch);
      if (store.getState()?.auth?.client) {
        setClient(store.getState()?.auth?.client?.data);
      }
    };
    if (client_state?.client === undefined) {
      getClient();
    }
    // eslint-disable-next-line
  }, [dispatch, store]);

  useEffect(() => {
    setClient(client_state?.client?.data);
    // eslint-disable-next-line
  }, [client_state]);

  useEffect(() => {
    const checkTomorrowHolidayStatus = async () => {
      await checkTomorrowHoliday(dispatch);
      if (store.getState()?.holiday?.tomorrowHoliday) {
        setHoliday(store.getState()?.holiday?.tomorrowHoliday?.data);
      }
    };
    if (holiday_state?.tomorrowHoliday === undefined) {
      checkTomorrowHolidayStatus();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHoliday(holiday_state?.tomorrowHoliday?.data);
    // eslint-disable-next-line
  }, [holiday_state]);

  useEffect(() => {
    const pair_range_notification = localStorage.getItem("range_notification");
    if (pair_range_notification) {
      setNewPairRange(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    // navbar-collapsed
    <>
      <div
        className={`${isMobile ? "mobile-sidebar-overlay" : ""} ${
          mobileSliderStatus === true ? "active" : ""
        }`}
        onClick={handleSlider}
      ></div>
      <nav
        className={
          isMobile
            ? mobileSliderStatus
              ? "pcoded-navbar mob-open"
              : "pcoded-navbar"
            : mobileSliderStatus
            ? "pcoded-navbar navbar-collapsed"
            : "pcoded-navbar"
        }
      >
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <Link to="/dashboard" className="b-brand">
              <div className="b-bg">
                <i className="feather icon-trending-up"></i>
              </div>
              <span className="b-title">Stock Market</span>
            </Link>
            <button
              className={mobileSliderStatus ? "mobile-menu on" : "mobile-menu"}
              id={isMobile ? "mobile-collapse1" : "mobile-collapse"}
              onClick={handleSlider}
            >
              <span></span>
            </button>
          </div>
          <div className="navbar-content scroll-div">
            <ul className="nav pcoded-inner-navbar">
              <li
                className={
                  path === "/dashboard"
                    ? "nav-item active mob-nav"
                    : "nav-item mob-nav"
                }
              >
                <Link to="/dashboard" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-home"></i>
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  path === "/trading" ||
                  path === "/trading-create" ||
                  path === "/trading-create-bulk" ||
                  path === `/trading-edit/${id}` ||
                  path === "/profile"
                    ? "nav-item active mob-nav"
                    : "nav-item mob-nav"
                }
              >
                <Link to="/trading" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-file-text"></i>
                  </span>
                  <span className="pcoded-mtext">Trading</span>
                </Link>
              </li>
              <li
                className={
                  path === "/order" ||
                  path === "/order-create" ||
                  path === `/order-edit/${id}`
                    ? "nav-item active mob-nav"
                    : "nav-item mob-nav"
                }
              >
                <Link to="/order" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-server"></i>
                  </span>
                  <span className="pcoded-mtext">Orders</span>
                </Link>
              </li>
              <li
                className={
                  path === "/trash-order"
                    ? "nav-item active mob-nav"
                    : "nav-item mob-nav"
                }
              >
                <Link to="/trash-order" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-server"></i>
                  </span>
                  <span className="pcoded-mtext">Trash Orders</span>
                </Link>
              </li>
              <li
                className={path === "/setting" ? "nav-item active" : "nav-item"}
              >
                <Link to="/setting" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-settings"></i>
                  </span>
                  <span className="pcoded-mtext">Account</span>
                </Link>
              </li>
              <li
                className={
                  path === "/symbols" || path === `/symbol-edit/${id}`
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/symbols" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-grid"></i>
                  </span>
                  <span className="pcoded-mtext">Add Symbols</span>
                </Link>
              </li>
              <li
                className={
                  path === "/pair-range" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/pair-range" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-message-square"></i>
                  </span>
                  <span className="pcoded-mtext">Pair Range</span>
                  <span className="mt-1" style={{ marginLeft: "75px" }}>
                    {newPairRange ? (
                      <img
                        src={AlertLogo}
                        alt=""
                        style={{ width: "28px" }}
                        className="ml-3"
                      />
                    ) : null}
                  </span>
                </Link>
              </li>
              {/* <li
                className={
                  path === "/volatility" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/volatility" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-activity"></i>
                  </span>
                  <span className="pcoded-mtext">Volatility</span>
                </Link>
              </li> */}
              <li
                className={
                  path === "/cycle-report" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/cycle-report" className="nav-link">
                  <span className="pcoded-micon">
                    <i className="feather icon-file"></i>
                  </span>
                  <span className="pcoded-mtext">Cycle Report</span>
                </Link>
              </li>

              {client?.role === true ? (
                <>
                  <li className="nav-item pcoded-menu-caption">
                    <label>Admin Panel</label>
                  </li>
                  <li
                    className={
                      path === "/pair-backtest" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link to="/pair-backtest" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">Pair Backtest</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/auto-backtest" ||
                      path === "/add-view-backtest-symbols" ||
                      path === "/add-bulk-backtest-symbols" ||
                      path === `/backtest-symbol-edit/${id}`
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/auto-backtest" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">Auto Backtest</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/repeat-backtest"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/repeat-backtest" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">Repeat Backtest</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/option-strategy-one"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/option-strategy-one" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">Option Strategy 1</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/option-strategy-two"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/option-strategy-two" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">ATM Strategy</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/option-strategy-three"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/option-strategy-three" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">OTM Strategy</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/ema-strategy"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link to="/ema-strategy" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-box"></i>
                      </span>
                      <span className="pcoded-mtext">EMA Strategy</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/symbol-update" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link to="/symbol-update" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-plus-square"></i>
                      </span>
                      <span className="pcoded-mtext">Symbols Status</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/master" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link to="/master" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-pie-chart"></i>
                      </span>
                      <span className="pcoded-mtext">Master</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/holiday" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link to="/holiday" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-calendar"></i>
                      </span>
                      <span className="pcoded-mtext">Holiday</span>
                      <span className="ml-5 mt-1">
                        {holiday ? (
                          <Tooltip
                            title={`Tomorrow is ${holiday}`}
                            placement="top"
                          >
                            <img
                              src={AlertLogo}
                              alt=""
                              style={{ width: "28px" }}
                              className="ml-3"
                            />
                          </Tooltip>
                        ) : null}
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/upload-excel" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link to="/upload-excel" className="nav-link">
                      <span className="pcoded-micon">
                        <i className="feather icon-upload"></i>
                      </span>
                      <span className="pcoded-mtext">Upload File</span>
                    </Link>
                  </li>
                  <li
                    className={
                      path === "/users"
                        ? "nav-item active mob-nav"
                        : "nav-item mob-nav"
                    }
                  >
                    <Link to="/users" className="nav-link ">
                      <span className="pcoded-micon">
                        <i className="feather icon-home"></i>
                      </span>
                      <span className="pcoded-mtext">Users</span>
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SideBar;
