import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { updateEmaStrategy, getEmaStrategyByID } from "../../../actions/emaStrategyAction";
import { getSymbolExpirys } from "../../../actions/optionStrategyOneAction";
import { getAllSymbols } from "../../../actions/optionStrategyCommanAction";
import { toast } from "react-toastify";


function EMAStrategyEdit() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account: "",
      symbol: "",
      expiry_date: "",
    },
  });


  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [expiryData, setExpiryData] = useState({});
  const [selectedDay, setSelectedDay] = useState([])
  const [expiries, setExpiries] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState("")
  const [selectedAccount, setSelectedAccount] = useState("")
  const [strategySymbols, setStrategySymbols] = useState([]);
  const [symbols, setAllSymbols] = useState([]);
  const [accountName, setAccountName] = useState();
  const [initialData, setInitialData] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handleDayChange = (day) => {
    if (selectedDay.includes(day)) {
      setSelectedDay(selectedDay.filter(x => x !== day))
    }
    else {
      setSelectedDay([...selectedDay, day])
    }
  }

  const onSubmit = async (data) => {
    const selectedAccount = data.account.split("|");
    const submissionData = {
      ...data,
      account_id: selectedAccount[0],
      account_name: selectedAccount[1],
    };
    submissionData["runningDays"] = selectedDay
    delete submissionData["account"]

    const selectedSymbol = submissionData?.symbol?.split("|")

    submissionData["companyName"] = selectedSymbol[0]
    submissionData["symbol"] = selectedSymbol[1]
    submissionData["exchange"] = selectedSymbol[2]
    submissionData["broker"] = accountName

    await updateEmaStrategy(dispatch, id, submissionData)
      .then((res) => {
        navigate("/ema-strategy")
        toast.success("Strategy updaetd successfully");
      })
      .catch((error) => {
        console.error("Error saving data", error);
      });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };


  const handleExpiry = async () => {
    if ((expiries.length === 0 || expiries.length === 1) && Object.keys(expiryData).length >= 2) {
      await getSymbolExpirys(dispatch, expiryData).then((res) => {
        setExpiries(res);
        setValue("expiry_date", selectedExpiry)
      }).catch((error) => { });
    }
  };

  const handleSymbol = (event) => {
    if (event.target.value) {
      const symbolDetail = event.target.value?.split("|");
      const symbol_company_name = symbolDetail[0]
      const symbol_name = symbolDetail[1]
      const symbol_exchange = symbolDetail[2]

      if (symbol_name && symbol_exchange) {
        setExpiryData({ ...expiryData, "symbol": symbol_name, "companyName": symbol_company_name, "exchange_segment": symbol_exchange });
      }

      setValue("expiry_date", "")
      setSelectedExpiry("")
      setExpiries([])
    }
  };

  const handleAccount = (event) => {
    const accountId = event.target.value.split("|")[0];
    setExpiryData({ ...expiryData, "account_id": accountId });
    setValue("symbol", "")
    setValue("expiry_date", "")
    setSelectedExpiry("")
    setSelectedAccount(event.target.value)
  };

  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      setAllSymbols(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };


  const fetchStrategyData = async () => {
    try {
      const strategyData = await getEmaStrategyByID(dispatch, id);
      if (strategyData) {
        setValue("account", `${strategyData?.data?.account_id}|${strategyData?.data?.account_name}`);
        setValue("expiry_date", strategyData?.data?.expiry_date);
        setExpiries([strategyData?.data?.expiry_date])
        setSelectedExpiry(strategyData?.data?.expiry_date);
        setSelectedAccount(`${strategyData?.data?.account_id}|${strategyData?.data?.account_name}`)
        setExpiryData({ "symbol": strategyData?.data?.symbol, "companyName": strategyData?.data?.companyName, "account_id": strategyData?.data?.account_id, "exchange_segment": strategyData?.data?.exchange })
        setSelectedDay(strategyData?.data?.runningDays);
        setInitialData(strategyData?.data)

        Object?.keys(strategyData?.data).forEach((key) => {
          setValue(key, strategyData?.data[key]);
        });

      }
    } catch (error) {
      console.error("Error fetching strategy data", error);
    }
  };

  function getValueByKey(key) {
    for (const obj of dropdownList?.setting) {
      const values = Object.values(obj);
      for (const value of values) {
        if (key in value) {
          return value[key];
        }
      }
    }
    return null;
  }

  useEffect(() => {
    getSettingData();
    getSymbolsData();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (id) {
      fetchStrategyData();
    }
    // eslint-disable-next-line
  }, [id])


  useEffect(() => {
    if (selectedAccount && symbols) {
      const accountName = selectedAccount?.split("|")[1];
      setAccountName(getValueByKey(accountName))
      const filteredSymbol = symbols?.filter(item => item.account_name === accountName);
      setStrategySymbols(filteredSymbol)
    }
    // eslint-disable-next-line
  }, [symbols, selectedAccount])

  useEffect(() => {
    if (initialData && strategySymbols) {
      setValue("symbol", `${initialData?.companyName}|${initialData?.symbol}|${initialData?.exchange}`);
    }
  }, [initialData, strategySymbols, setValue]);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/ema-strategy" style={{ fontWeight: 600 }}>
                            EMA Strategy
                          </Link>
                        </li>
                      </ul>
                      <div className="page-header-title d-flex justify-content-between pb-3">
                        <div className="ListWithBadge">
                          <h3 className="m-b-20 mr-2">Edit Strategy</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 pr-0">
                            <label>Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>Select...</option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];

                                return (
                                  <option key={id} value={`${id}|${key}`}>
                                    {key}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">Account is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                              onChange={handleSymbol}
                            >
                              <option value="" disabled>Select...</option>
                              {strategySymbols?.map((data, index) => {
                                return accountName === "ANGEL" ? (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.companyName}</option>
                                ) : (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.shortName}</option>
                                )
                              })}
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">Symbol is required</span>
                            )}
                          </div>

                          <div className="form-group col-md-3 pr-0">
                            <label>Expiry date*</label>
                            <Controller
                              name="expiry_date"
                              control={control}
                              defaultValue=""
                              {...register("expiry_date", { required: true })}
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  {...field}
                                  onClick={handleExpiry}
                                >
                                  <option value="" disabled>Select...</option>
                                  {expiries?.map((data, index) => (
                                    <option key={index} value={data}>{data}</option>
                                  ))}
                                </select>
                              )}
                            />
                            {errors.expiry_date && (
                              <span className="validationAlert">Expiry date is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Running day*</label>
                            <div className="multi-select-tab-wrapper">
                              {
                                ["Mon", "Tue", "Wed", "Thu", "Fri"].map(x => {
                                  return <div className={`tab ${selectedDay?.includes(x) ? "tab-active" : ""}`} onClick={() => handleDayChange(x)} >
                                    {x}
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-2 pr-0">
                            <label>Lower EMA Value*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Lower ema value"
                              {...register(`lower_ema_value`, {
                                required: "Lower ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.lower_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.lower_ema_value?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Upper EMA Value*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Upper ema value"
                              {...register(`upper_ema_value`, {
                                required: "Upper ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.upper_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.upper_ema_value?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Quantity*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Quantity"
                              {...register(`quantity`, {
                                required: "Quantity is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.quantity && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.quantity?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>Select...</option>
                              <option value="5s">5second</option>
                              <option value="10s">10second</option>
                              <option value="15s">15second</option>
                              <option value="30s">30second</option>
                              <option value="1m">1minute</option>
                              <option value="3m">3minute</option>
                              <option value="5m">5minute</option>
                              <option value="15m">15minute</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">Interval is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Side*</label>
                            <select
                              className="form-control"
                              {...register("side", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>Select...</option>
                              <option value="call">Call</option>
                              <option value="put">Put</option>
                              <option value="both">Both</option>
                            </select>
                            {errors.side && (
                              <span className="validationAlert">Side is required</span>
                            )}
                          </div>
                        </div>

                        <div className="row col-md-12 mb-4 pr-0">
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Profit</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Profit"
                              {...register(`overall_profit`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_profit && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_profit?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Overall Loss</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Overall Loss"
                              {...register(`overall_loss`, {
                                required: false,
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.overall_loss && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.overall_loss?.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 px-4"
                          >
                            Update Strategy
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EMAStrategyEdit;
